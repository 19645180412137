<template>
  <v-container fluid class="ma-0 pa-0" dense>
    <v-row class="ma-0 pa-0">
      <v-col cols="12"> <Header /> </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" :class="MobileClass"> <QuickSearch /> </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewPage overrideSublist="mainpage" hierarchyStyle="one" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header";
import QuickSearch from "@/components/QuickSearch";
import ViewPage from "@/views/ViewPage";

export default {
  components: {
    Header,
    ViewPage,
    QuickSearch,
  },

  mounted() {
    this.$store.dispatch("menu/setQuickLink", "home");
  },

  computed: {
    MobileClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return undefined;
    },
   
  },
};
</script>

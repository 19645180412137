<template>
    <v-card color="primary" :class="MobileClass" outlined min-width="350">
    <!-- Header -->
    <v-card-title class="quicksearch pb-0">
      <v-autocomplete
        v-model="toolname"
        :items="AllTools"
        prepend-icon="mdi-database-search-outline"
        hide-no-data
        class="ma-0 pa-0"
        color="primary"
        label="Quick-Search: Equipment and Staff"
        placeholder="Start typing to Search"
        @input="showToolDialog()"
      >
      </v-autocomplete>
    </v-card-title>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
export default {
  data: () => ({
    toolname: null,
  }),
  watch: {
    // watch expansion/selection and emit as event
    // warning: cyclical watchers... must adjust on level up
    toolname: function (val) {
      if (val == null) return;
      //this.showToolDialog();
    },
  },
  computed: {
    MobileClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return "undefined";
    },
    
    AllTools() {
      const toolList = this.$store.getters["tools/getTools"];
      var toolNames = [];
      for (const [, entry] of Object.entries(toolList)) {
        if (entry.Type === "Tool") {
          toolNames.push(entry.Name);
        } else {
          if (entry.Special === "Staff") {
            toolNames.push(entry.Label);
          }
        }
      }
      return toolNames;
    },
    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    getTheme(fieldname) {
      var entry = this.$vuetify.theme.themes[this.theme];
      if (entry == undefined) return undefined;
      return entry[fieldname];
    },
    async showToolDialog() {
      var lookupname = this.toolname;
      if (lookupname == null) return;
      // if it's a staff name, translate to toolname
      const toolList = this.$store.getters["tools/getTools"];
      if (toolList[lookupname] === undefined) {
        for (const [, entry] of Object.entries(toolList)) {
          if ((entry.Special === "Staff") && (entry.Label === lookupname)) {
            lookupname = entry.Name;
            break
          }
        }
      }
      await this.$root.$onetooldialog([lookupname], 0, {
        authObject: this.authObject,
      });
      this.toolname = null;
    },
  },
};
</script>

<template>
  <v-card class="mx-auto" color="primary" outlined v-if="$vuetify.breakpoint.mdAndUp">
    <v-img src="/images/banner.jpg" height="160px"></v-img>
    <v-card-title class="mainheader"
      >Welcome to MIT.nano - Equipment & Capabilities</v-card-title
    >
    <v-card-text class="mainheader">
      This site describes the shared tools and instruments available at MIT.nano. Check
      out recent tool additions below, find out how to get started in the lab, or browse
      capabilities or questions via the menu.
    </v-card-text>
    <v-card-text class="white--text">
      NOTICE: This site is currently being constructed, and is not yet complete. Tool
      information is being populated and may be inaccurate as we're still editing details.
      Usage fees were pulled form the charging algorithm, and currently are meant as a
      guideline only. Until we develop an accurate data-conversion method, some charges
      are shown in their simplified form (e.g. without precious metal or excess time
      add-ons). For accurate and binding estimates, please contact us.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    getTheme(fieldname) {
      var entry = this.$vuetify.theme.themes[this.theme];
      if (entry == undefined) return undefined;
      return entry[fieldname];
    },
  },
};
</script>
